<template lang="pug">
div
  fi-form-field(:label = '$t("reportingType")' :label-cols = 3 )
    fi-select(
      v-model = 'vehicleData.assetReportingType'
      :options       = 'reportingTypes'
      sm
    )
  .loan-collateral-asset-vehicle-fields-edit.animated.fadeIn.flex-row-direction
    div.width-50.margin-right-20
      fi-form-field(:label = '$t("vehicleBrand")')
        input.form-control.form-control-sm(
          v-model.trim = 'vehicleData.vehicleBrand'
          type         = 'text'
        )
      fi-form-field(:label = '$t("vehicleModel")')
        input.form-control.form-control-sm(
          v-model.trim = 'vehicleData.vehicleModel'
          type         = 'text'
        )
      fi-form-field(:label = '$t("vehicleProductionYear")')
        input.form-control.form-control-sm(
          v-model.number = 'vehicleData.vehicleProductionYear'
          type           = 'text'
        )
      fi-form-field(:label = '$t("vehiclePower")')
        input.form-control.form-control-sm(
          v-model.number = 'vehicleData.vehiclePower'
          type           = 'text'
        )
      fi-form-field(:label = '$t("vehicleMileage")')
        input.form-control.form-control-sm(
          v-model.number = 'vehicleData.vehicleMileage'
          type           = 'text'
        )
      fi-form-field(:label = '$t("vehicleSerialNumber")')
        input.form-control.form-control-sm(
          v-model.trim = 'vehicleData.vehicleSerialNumber'
          type         = 'text'
        )
      fi-form-field(:label = '$t("vehicleRegistrationNumber")')
        input.form-control.form-control-sm(
          v-model.trim = 'vehicleData.vehicleRegistrationNumber'
          type         = 'text'
        )
      fi-form-field(:label = '$t("sellerId")')
        fi-customer-select(v-model = 'selectedCustomerModel')

      fi-form-field(:label = '$t("sellerContactName")')
        input.form-control.form-control-sm(
          v-model = 'vehicleData.sellerContactName'
          type    = 'text'
        )

      fi-form-field(:label = '$t("sellerPhone")')
        fi-phone-input.form-control-sm.form-control(
          v-model    = 'vehicleData.sellerPhone'
          :countries = "countries"
        )

      fi-form-field(:label = '$t("sellerEmail")')
        input.form-control.form-control-sm(
          v-model = 'vehicleData.sellerEmail'
          type    = 'text'
        )
      fi-form-field(:label = '$t("postalСode")')
        input.form-control.form-control-sm(
          v-model.number = 'vehicleData.warrantyPostalCode'
          type           = 'text'
        )
      fi-form-field(:label = '$t("warrantyOwnerName")')
        .flex-row-direction
          fi-customer-select(
            v-model      = 'selectedOwnerCustomerModel'
            :placeholder = '$t("existingCustomerPlaceholder")'
          )
          .input-group-append
            button.btn.btn-sm.btn-primary(
              @click.prevent = 'addSelectedUserToOwnerList'
            )
              i.far.fa-plus-square.fa-lg
      div
        span.pr-2.bold.offset-sm-6 - {{ $t('or') }} -
        customer-new-brief(
          v-if    = 'createNewCustomer'
          @cancel = 'createNewCustomer = false'
          @submit = 'onCreateCustomer'
        )
        button.btn.btn-link.px-0(
          v-else
          @click.prevent = 'createNewCustomer = true'
        ) {{ $t('createCustomer') }}
        .flex-column-direction(v-if = 'showOwnerList')
          <table style="width:100%">
            <tr style = 'text-align: center;'>
              <th style = 'width: 50%'></th>
              <th style = 'width: 10%'>{{ $t("primary") }}</th>
              <th style = 'width: 20%'>{{ $t("ownerShip") }}</th>
              <th style = 'width: 10%'></th>
            </tr>
            template(v-for = '(owner, index) in assetOwner')
              <tr>
                <td class = 'bold'> {{ owner.customerName }} </td>
                <td style = 'display: flex;'>
                  input(
                    style   = 'width: 100%; height: 35px;'
                    v-model = 'owner.primaryOwner'
                    type    = 'checkbox'
                    @change = 'selectOwnerPrimary(index)'
                  )
                </td>
                <td>
                  percent-input(
                    v-model = 'owner.ownershipPercentage')
                </td>
                <td style = 'text-align: center;'>
                  i.far.fa-trash-alt.fa-lg.text-danger.cursor-pointer(
                    @click = 'removeOwnerFromOwnerList(index)'
                  )
                </td>
              </tr>
          </table>
      fi-form-field(:label = '$t("assetInfo")')
        fi-rich-text-editor(v-model = 'assetData.assetInfo')
    div.width-50
      fi-form-field(:label = '$t("valueType")')
        fi-select(
          v-model.number = 'vehicleData.valueType'
          :options       = 'valueTypes'
          sm
        )
      fi-form-field(:label = '$t("valuationType")')
        fi-select(
          v-model.number = 'vehicleData.valuationType'
          :options       = 'valuationTypes'
          sm
        )
      fi-form-field(:label = '$t("locationCode")')
        fi-select(
          v-model.number = 'vehicleData.collateralLocationCode'
          :options       = 'locationCodes'
          sm
        )
      fi-form-field(:label = '$t("assetStatus")')
        fi-select(
          v-model.number = 'vehicleData.assetStatus'
          :options       = 'assetStatus'
          sm
        )
      //- fi-form-field(:label = '$t("appraisalPartner")')
      //-   fi-customer-select(v-model = 'selectedAppraisalPartnerModel')
      fi-form-field(:label = '$t("assetSoldValue")')
        input.form-control.form-control-sm(
          v-model = 'vehicleData.assetSoldValue'
          type           = 'text'
        )
      fi-form-field(:label = '$t("soldDate")')
        fi-datepicker(
          v-model = 'vehicleData.soldDate'
          sm
        )
      fi-form-field(:label = '$t("warrantyArea")')
        .form-row
          .col
            input.form-control.form-control-sm(
              v-model.number = 'vehicleData.warrantyArea'
              type           = 'text'
            )
          .col
            fi-select(
              v-model.number = 'vehicleData.areaSizeTypeId'
              :options       = 'optionsFromClassifier("immovableAreaSizeType")'
              required
              sm
            )
      fi-form-field(
        :label = '$t("value")'
        :state = '!state.assetData.value.$error'
      )
        template(#error)
          .error-message(v-if = '!state.assetData.value.required') {{ $t('common:required') }}
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'assetData.value'
          :class         = '{ "is-invalid": state.assetData.value.$error }'
        )
      fi-form-field(:label = '$t("valueDate")')
        fi-datepicker(
          v-model = 'assetData.valueDate'
          sm
        )
      fi-form-field(
        :label = '$t("allocatedValue")'
      )
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'assetData.allocatedValue'
        )
      fi-form-field(:label = '$t("appraisalReport")')
        fi-switch.mt-1(
          v-model   = 'assetData.appraisalReport'
          :on-text  = '$t("common:yes")'
          :off-text = '$t("common:no")'
        )
      .animated.fadeIn(v-if = 'assetData.appraisalReport')
        fi-form-field(:label = '$t("appraisalPartnerName")')
          input.form-control.form-control-sm(
            v-model.number = 'assetData.appraisalPartnerName'
            type           = 'text'
          )
        fi-form-field(:label = '$t("appraisalValue")')
          fi-money-input.form-control.form-control-sm(
            v-model.number = 'assetData.appraisalValue'
            type           = 'text'
          )
      fi-form-field(:label = '$t("insuranceMandatory")')
        fi-switch.mt-1(
          v-model   = 'assetData.insuranceMandatory'
          :on-text  = '$t("common:yes")'
          :off-text = '$t("common:no")'
        )
      fi-form-field(:label = '$t("insuranceCompany")')
        input.form-control.form-control-sm(
          v-model.number = 'assetData.insuranceCompany'
          type           = 'text'
        )
      fi-form-field(:label = '$t("insuredUntil")')
        fi-datepicker(
          v-model = 'assetData.insuredUntil'
          sm
        )
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import { mapGetters } from 'vuex'
import FiMoneyInput from '@/components/FiMoneyInput'
import { parseSellerData } from '@/helpers'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import PercentInput from '@/components/PercentInput'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'

export default {
  name: 'loan-collateral-asset-vehicle-fields-edit',

  components: {
    FiCustomerSelect,
    FiMultiselect,
    FiPhoneInput,
    FiMoneyInput,
    FiFormField,
    FiSelect,
    FiDatepicker,
    FiSwitch,
    FiRichTextEditor,
    PercentInput,
    CustomerNewBrief
  },

  inject: ['storeModule'],

  props: {
    vehicleData: {
      type: Object,
      required: true
    },
    assetData: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    seller: {
      type: Object,
      default: null
    },
    appraisalPartnerData: {
      type: Object,
      default: null
    },
    assetOwner: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    createNewCustomer: false,
    selectedCustomer: vm.seller,
    selectedAppraisalPartner: vm.appraisalPartnerData,
    selectedOwnerCustomer: null,
    showOwnerList: false,
    deleting: false,
    ownerList: [],
    assetOwnerData: []
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    countries () {
      return this.optionsFromClassifier('countries').map(({ text }) => text)
    },
    assetStatus () {
      return this.optionsFromClassifier('ASSET_STATUS', true)
    },
    reportingTypes () {
      return this.optionsFromClassifier('TYP_PRTCTN', true)
    },
    valueTypes () {
      return this.optionsFromClassifier('TYP_PRTCTN_VL', true)
    },
    valuationTypes () {
      return this.optionsFromClassifier('PRTCTN_VLTN_APPRCH', true)
    },
    locationCodes () {
      return this.optionsFromClassifier('ISO3166_NUTS_DSJNT', true)
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        Object.entries(parseSellerData(selected)).forEach(([key, value]) => {
          this.vehicleData[key] = value
        })
      }
    },
    selectedOwnerCustomerModel: {
      get () {
        return this.selectedOwnerCustomer
      },
      async set (selected) {
        this.selectedOwnerCustomer = selected
      }
    },
    selectedAppraisalPartnerModel: {
      get () {
        return this.selectedAppraisalPartner
      },
      set (selected) {
        this.selectedAppraisalPartner = selected
        this.vehicleData.appraisalPartnerId = selected.id
      }
    },
    lengthOwnerList () {
      return this.assetOwner.length
    }
  },
  watch: {
    lengthOwnerList: {
      handler (value) {
        value ? this.showOwnerList = true : this.showOwnerList = false
      },
      deep: true
    }
  },
  created () {
    if (this.vehicleData.assetsOwners.length) {
      this.showOwnerList = true
      this.ownerList = this.vehicleData.assetsOwners
    }
    if (!this.assetData.value) {
      this.selectedCustomerModel = this.seller
      if (this.appraisalPartnerData) {
        this.selectedAppraisalPartner = this.appraisalPartnerData
      }
    }
  },
  methods: {
    async onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedOwnerCustomerModel = customer
    },
    addSelectedUserToOwnerList () {
      if (this.selectedOwnerCustomer) {
        this.assetOwner.push({ customerName: this.selectedOwnerCustomer.customerName, primaryOwner: false, ownershipPercentage: 0, customerId: this.selectedOwnerCustomer.id })
      }
    },
    selectOwnerPrimary (index) {
      if (this.assetOwner[index].primary) {
        this.assetOwner.forEach((el, id) => {
          if (index !== id) {
            el.primary = false
          }
        })
      }
    },
    removeOwnerFromOwnerList (index) {
      this.assetOwner.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-row-direction {
    display: flex;
  }
  .flex-column-direction {
    display: flex; flex-direction: column;
  }
  .bold {
    font-weight: bold;
    border: none !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .width-50 {
    width: 50%;
  }
  .margin-right-20 {
    margin-right: 20px
  }
  </style>


<i18n>
en:
  vehicleBrand:              "Brand"
  vehicleModel:              "Model"
  vehicleProductionYear:     "Production year"
  vehiclePower:              "Power, kw"
  vehicleMileage:            "Mileage, km"
  vehicleSerialNumber:       "Serial number"
  vehicleRegistrationNumber: "Registration number"
  sellerId:                  "Seller"
  sellerContactName:         "Seller contact name"
  sellerPhone:               "Seller contact phone"
  sellerEmail:               "Seller contact e-mail"
  value:                     "Value"
  valueDate:                 "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:           "Appraisal report"
  appraisalPartnerName:      "Appraisal partner"
  appraisalValue:            "Appraisal value"
  insuranceMandatory:        "Insurance mandatory"
  insuranceCompany:          "Insurance company"
  insuredUntil:              "Insured until"
  assetInfo:                 "Asset info"
  postalСode:            "Postal code"
  soldDate:              "Sold date"
  assetSoldValue:        "Asset sold value"
  appraisalPartner:      "Appraisal partner"
  reportingType:         "Reporting type"
  valueType:             "Value type"
  valuationType:         "Valuation type"
  locationCode:          "Location code"
  assetStatus:           "Asset status"
  warrantyArea:              "Area"
  warrantyOwnerName:               "Owner name"
  createCustomer:                  "Create customer"
  existingCustomerPlaceholder:     "Select customer"
et:
  vehicleBrand:              "Brand"
  vehicleModel:              "Model"
  vehicleProductionYear:     "Production year"
  vehiclePower:              "Power, kw"
  vehicleMileage:            "Mileage, km"
  vehicleSerialNumber:       "Serial number"
  vehicleRegistrationNumber: "Registration number"
  sellerId:                  "Seller"
  sellerContactName:         "Seller contact name"
  sellerPhone:               "Seller contact phone"
  sellerEmail:               "Seller contact e-mail"
  value:                     "Value"
  valueDate:                 "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:           "Appraisal report"
  appraisalPartnerName:      "Appraisal partner"
  appraisalValue:            "Appraisal value"
  insuranceMandatory:        "Insurance mandatory"
  insuranceCompany:          "Insurance company"
  insuredUntil:              "Insured until"
  assetInfo:                 "Asset info"
  postalСode:            "Postal code"
  soldDate:              "Sold date"
  assetSoldValue:        "Asset sold value"
  appraisalPartner:      "Appraisal partner"
  reportingType:         "Reporting type"
  valueType:             "Value type"
  valuationType:         "Valuation type"
  locationCode:          "Location code"
  assetStatus:           "Asset status"
  warrantyArea:              "Area"
  warrantyOwnerName:               "Owner name"
  createCustomer:                  "Create customer"
  existingCustomerPlaceholder:     "Select customer"
ru:
  vehicleBrand:              "Brand"
  vehicleModel:              "Model"
  vehicleProductionYear:     "Production year"
  vehiclePower:              "Power, kw"
  vehicleMileage:            "Mileage, km"
  vehicleSerialNumber:       "Serial number"
  vehicleRegistrationNumber: "Registration number"
  sellerId:                  "Seller"
  sellerContactName:         "Seller contact name"
  sellerPhone:               "Seller contact phone"
  sellerEmail:               "Seller contact e-mail"
  value:                     "Value"
  valueDate:                 "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:           "Appraisal report"
  appraisalPartnerName:      "Appraisal partner"
  appraisalValue:            "Appraisal value"
  insuranceMandatory:        "Insurance mandatory"
  insuranceCompany:          "Insurance company"
  insuredUntil:              "Insured until"
  assetInfo:                 "Asset info"
  postalСode:            "Postal code"
  soldDate:              "Sold date"
  assetSoldValue:        "Asset sold value"
  appraisalPartner:      "Appraisal partner"
  reportingType:         "Reporting type"
  valueType:             "Value type"
  valuationType:         "Valuation type"
  locationCode:          "Location code"
  assetStatus:           "Asset status"
  warrantyArea:              "Area"
  warrantyOwnerName:               "Owner name"
  createCustomer:                  "Create customer"
  existingCustomerPlaceholder:     "Select customer"
</i18n>
